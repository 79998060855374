import {
    SET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT,
    CLEAR_TRACKIER_PUBLISHER_MANAGEMENT_REPORT,
    GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_SUCCESS,
    GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_FAILED,
} from '../../actions/type';

const initialState = { data: null, status: 'idle' };

export default function trackierPublisherManagementReportReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT:
            return {
                ...state,
                data,
            }
        case CLEAR_TRACKIER_PUBLISHER_MANAGEMENT_REPORT:
            return {
                ...state,
                data: null,
            }
        case GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}