import {
    SET_TRACKIER_DASHBOARD_INSIGHT,
    CLEAR_TRACKIER_DASHBOARD_INSIGHT,
    GET_TRACKIER_DASHBOARD_INSIGHT_SUCCESS,
    GET_TRACKIER_DASHBOARD_INSIGHT_FAILED
} from '../../actions/type';

const initialStateTrackierDashboardInsight = { data: null, status: 'idle' };

export default function trackierDashboardInsightReducer(state = initialStateTrackierDashboardInsight, action) {
    const { type, data } = action;

    switch (type) {
        case SET_TRACKIER_DASHBOARD_INSIGHT:
            return {
                ...state,
                data,
            }
        case CLEAR_TRACKIER_DASHBOARD_INSIGHT:
            return {
                ...state,
                data: null,
            }
        case GET_TRACKIER_DASHBOARD_INSIGHT_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_TRACKIER_DASHBOARD_INSIGHT_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}