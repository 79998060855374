import Container from './components/Container';
import './App.css';
import { useDispatch } from 'react-redux';
import { me } from './stores/actions/auth';
import authHeader from './services/auth-header';

import { ConfigProvider } from 'antd';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import updateLocale from 'dayjs/plugin/updateLocale';
import locale from "antd/es/locale/en_GB";

dayjs.extend(updateLocale);
dayjs.updateLocale('en-gb', { weekStart: 1 });

function App() {
  const dashboardToken = authHeader()['dashboard-token'];
  const dispatch = useDispatch();

  if (dashboardToken) {
    dispatch(me());
  }

  return (
    <div className="App">
      <ConfigProvider locale={locale}>
        <Container />
      </ConfigProvider>
    </div>
  );
}

export default App;
