export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const SET_REPORT = 'SET_REPORT';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILED = 'GET_REPORT_FAILED';

// VOLUUM TYPE
// OFFER
export const SET_OFFER_CAMPAIGNS = 'SET_OFFER_CAMPAIGNS';
export const CLEAR_OFFER_CAMPAIGNS = 'CLEAR_OFFER_CAMPAIGNS';
export const GET_OFFER_CAMPAIGNS_SUCCESS = 'GET_OFFER_CAMPAIGNS_SUCCESS';
export const GET_OFFER_CAMPAIGNS_FAILED = 'GET_OFFER_CAMPAIGNS_FAILED';
export const UPDATE_OFFER_CAMPAIGN = 'UPDATE_OFFER_CAMPAIGN';
export const UPDATE_OFFER_CAMPAIGN_SUCCESS = 'UPDATE_OFFER_CAMPAIGN_SUCCESS';
export const UPDATE_OFFER_CAMPAIGN_FAILED = 'UPDATE_OFFER_CAMPAIGN_FAILED';

// CAMPAIGN
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED';

// ADVERTISER
export const SET_ADVERTISER = 'SET_ADVERTISER';
export const CLEAR_ADVERTISER = 'CLEAR_ADVERTISER';
export const GET_ADVERTISER_SUCCESS = 'GET_ADVERTISER_SUCCESS';
export const GET_ADVERTISER_FAILED = 'GET_ADVERTISER_FAILED';

// COUNTRY
export const SET_COUNTRY = 'SET_COUNTRY';
export const CLEAR_COUNTRY = 'CLEAR_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

// TRAFFIC SOURCE
export const SET_TRAFFIC_SOURCE = 'SET_TRAFFIC_SOURCE';
export const CLEAR_TRAFFIC_SOURCE = 'CLEAR_TRAFFIC_SOURCE';
export const GET_TRAFFIC_SOURCE_SUCCESS = 'GET_TRAFFIC_SOURCE_SUCCESS';
export const GET_TRAFFIC_SOURCE_FAILED = 'GET_TRAFFIC_SOURCE_FAILED';

// DAILY
export const SET_VOLUUM_DAILY = 'SET_VOLUUM_DAILY';
export const CLEAR_VOLUUM_DAILY = 'CLEAR_VOLUUM_DAILY';
export const GET_VOLUUM_DAILY_SUCCESS = 'GET_VOLUUM_DAILY_SUCCESS';
export const GET_VOLUUM_DAILY_FAILED = 'GET_VOLUUM_DAILY_FAILED';

// TRACKIER TYPE
// CAMPAIGN
export const SET_TRACKIER_CAMPAIGNS = 'SET_TRACKIER_CAMPAIGNS';
export const CLEAR_TRACKIER_CAMPAIGNS = 'CLEAR_TRACKIER_CAMPAIGNS';
export const GET_TRACKIER_CAMPAIGNS_SUCCESS = 'GET_TRACKIER_CAMPAIGNS_SUCCESS';
export const GET_TRACKIER_CAMPAIGNS_FAILED = 'GET_TRACKIER_CAMPAIGNS_FAILED';

// PUBLISHER
export const SET_TRACKIER_PUBLISHER = 'SET_TRACKIER_PUBLISHER';
export const CLEAR_TRACKIER_PUBLISHER = 'CLEAR_TRACKIER_PUBLISHER';
export const GET_TRACKIER_PUBLISHER_SUCCESS = 'GET_TRACKIER_PUBLISHER_SUCCESS';
export const GET_TRACKIER_PUBLISHER_FAILED = 'GET_TRACKIER_PUBLISHER_FAILED';

// COMPARISON PUBLISHER
export const SET_TRACKIER_COMPARISON_PUBLISHER = 'SET_TRACKIER_COMPARISON_PUBLISHER';
export const CLEAR_TRACKIER_COMPARISON_PUBLISHER = 'CLEAR_TRACKIER_COMPARISON_PUBLISHER';
export const GET_TRACKIER_COMPARISON_PUBLISHER_SUCCESS = 'GET_TRACKIER_COMPARISON_PUBLISHER_SUCCESS';
export const GET_TRACKIER_COMPARISON_PUBLISHER_FAILED = 'GET_TRACKIER_COMPARISON_PUBLISHER_FAILED';

// TRACKIER PUBLISHER MANAGEMENT
export const SET_TRACKIER_PUBLISHER_MANAGEMENT = 'SET_TRACKIER_PUBLISHER_MANAGEMENT';
export const CLEAR_TRACKIER_PUBLISHER_MANAGEMENT = 'CLEAR_TRACKIER_PUBLISHER_MANAGEMENT';
export const GET_TRACKIER_PUBLISHER_MANAGEMENT_SUCCESS = 'GET_TRACKIER_PUBLISHER_MANAGEMENT_SUCCESS';
export const GET_TRACKIER_PUBLISHER_MANAGEMENT_FAILED = 'GET_TRACKIER_PUBLISHER_MANAGEMENT_FAILED';

// TRACKIER PUBLISHER MANAGEMENT REPORT
export const SET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT = 'SET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT';
export const CLEAR_TRACKIER_PUBLISHER_MANAGEMENT_REPORT = 'CLEAR_TRACKIER_PUBLISHER_MANAGEMENT_REPORT';
export const GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_SUCCESS = 'GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_SUCCESS';
export const GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_FAILED = 'GET_TRACKIER_PUBLISHER_MANAGEMENT_REPORT_FAILED';

// DAILY
export const SET_TRACKIER_DAILY = 'SET_TRACKIER_DAILY';
export const CLEAR_TRACKIER_DAILY = 'CLEAR_TRACKIER_DAILY';
export const GET_TRACKIER_DAILY_SUCCESS = 'GET_TRACKIER_DAILY_SUCCESS';
export const GET_TRACKIER_DAILY_FAILED = 'GET_TRACKIER_DAILY_FAILED';

// DASHBOARD
// VOLUUM
export const SET_VOLUUM_DASHBOARD_INSIGHT = 'SET_VOLUUM_DASHBOARD_INSIGHT';
export const CLEAR_VOLUUM_DASHBOARD_INSIGHT = 'CLEAR_VOLUUM_DASHBOARD_INSIGHT';
export const GET_VOLUUM_DASHBOARD_INSIGHT_SUCCESS = 'GET_VOLUUM_DASHBOARD_INSIGHT_SUCCESS';
export const GET_VOLUUM_DASHBOARD_INSIGHT_FAILED = 'GET_VOLUUM_DASHBOARD_INSIGHT_FAILED';

// TRACKIER
export const SET_TRACKIER_DASHBOARD_INSIGHT = 'SET_TRACKIER_DASHBOARD_INSIGHT';
export const CLEAR_TRACKIER_DASHBOARD_INSIGHT = 'CLEAR_TRACKIER_DASHBOARD_INSIGHT';
export const GET_TRACKIER_DASHBOARD_INSIGHT_SUCCESS = 'GET_TRACKIER_DASHBOARD_INSIGHT_SUCCESS';
export const GET_TRACKIER_DASHBOARD_INSIGHT_FAILED = 'GET_TRACKIER_DASHBOARD_INSIGHT_FAILED';

export const SET_TRACKIER_CONVERSION_REPORT = 'SET_TRACKIER_CONVERSION_REPORT';
export const CLEAR_TRACKIER_CONVERSION_REPORT = 'CLEAR_TRACKIER_CONVERSION_REPORT';
export const GET_TRACKIER_CONVERSION_REPORT_SUCCESS = 'GET_TRACKIER_CONVERSION_REPORT_SUCCESS';
export const GET_TRACKIER_CONVERSION_REPORT_FAILED = 'GET_TRACKIER_CONVERSION_REPORT_FAILED';

export const SET_TRACKIER_TOP_PUBLISHER_REPORT = 'SET_TRACKIER_TOP_PUBLISHER_REPORT';
export const CLEAR_TRACKIER_TOP_PUBLISHER_REPORT = 'CLEAR_TRACKIER_TOP_PUBLISHER_REPORT';
export const GET_TRACKIER_TOP_PUBLISHER_REPORT_SUCCESS = 'GET_TRACKIER_TOP_PUBLISHER_REPORT_SUCCESS';
export const GET_TRACKIER_TOP_PUBLISHER_REPORT_FAILED = 'GET_TRACKIER_TOP_PUBLISHER_REPORT_FAILED';

export const SET_TRACKIER_DAILY_CLICK_REPORT = 'SET_TRACKIER_DAILY_CLICK_REPORT';
export const CLEAR_TRACKIER_DAILY_CLICK_REPORT = 'CLEAR_TRACKIER_DAILY_CLICK_REPORT';
export const GET_TRACKIER_DAILY_CLICK_REPORT_SUCCESS = 'GET_TRACKIER_DAILY_CLICK_REPORT_SUCCESS';
export const GET_TRACKIER_DAILY_CLICK_REPORT_FAILED = 'GET_TRACKIER_DAILY_CLICK_REPORT_FAILED';
