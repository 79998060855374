import {
    SET_VOLUUM_DASHBOARD_INSIGHT,
    CLEAR_VOLUUM_DASHBOARD_INSIGHT,
    GET_VOLUUM_DASHBOARD_INSIGHT_SUCCESS,
    GET_VOLUUM_DASHBOARD_INSIGHT_FAILED
} from '../../actions/type';

const workspaces = {
    'All': 'All',
    '663632c9-5c92-4b0d-9a74-528b9d04fbcd': 'ADZUMO MB',
    '61eb72c1-afd4-4193-be15-1f2cde62009a': 'ADZUMO REMNANT',
    'ea0e0ac5-0164-4744-a4a0-dcfd395ac89e': 'Internal',
    'f12f17b7-3c99-4042-a797-4004ea94275b': 'Torazzo',
    '6ae699c9-76a6-4d14-9e83-8b40f64b5315': 'Torazzo Direct Offers',

    'All zak@dtf4media.com': 'All zak@dtf4media.com',
    '65a9292f-a9b6-4afe-8e5b-fc14e7e55486': 'Adzumo CPA',
    '296ce143-1159-46ed-a230-924d22f6c5e4': 'Adzumo Internal',
};

const initialStateVoluumDashboardInsight = { data: null, status: 'idle' };

export default function voluumDashboardInsightReducer(state = initialStateVoluumDashboardInsight, action) {
    const { type, data } = action;

    switch (type) {
        case SET_VOLUUM_DASHBOARD_INSIGHT:
            const initState = {};

            if (!data || data.failed) {
                initState[workspaces[data.workspaces]] = null;
                return {
                    ...state,
                    data: {
                        visits: 0,
                        cost: 0,
                        revenue: 0,
                        profit: 0,
                        failed: true,
                    },
                }
            }

            const { workspaces: workspacesValue, ...rest } = data;
            initState[workspaces[workspacesValue]] = rest;

            return {
                ...state,
                ...initState,
                data,
            }
        case CLEAR_VOLUUM_DASHBOARD_INSIGHT:
            return {
                ...state,
                data: null,
            }
        case GET_VOLUUM_DASHBOARD_INSIGHT_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_VOLUUM_DASHBOARD_INSIGHT_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}
