import {
    SET_COUNTRY,
    CLEAR_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILED,
} from '../../actions/type';

const initialState = { data: null, status: 'idle' };

export default function voluumCountryReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_COUNTRY:
            return {
                ...state,
                data,
            }
        case CLEAR_COUNTRY:
            return {
                ...state,
                data: null,
            }
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_COUNTRY_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}