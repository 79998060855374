import {
    SET_TRACKIER_TOP_PUBLISHER_REPORT,
    CLEAR_TRACKIER_TOP_PUBLISHER_REPORT,
    GET_TRACKIER_TOP_PUBLISHER_REPORT_SUCCESS,
    GET_TRACKIER_TOP_PUBLISHER_REPORT_FAILED,
} from '../../actions/type';

const initialState = { data: null, status: 'idle' };

export default function trackierTopPublisherReportReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_TRACKIER_TOP_PUBLISHER_REPORT:
            return {
                ...state,
                data,
            }
        case CLEAR_TRACKIER_TOP_PUBLISHER_REPORT:
            return {
                ...state,
                data: null,
            }
        case GET_TRACKIER_TOP_PUBLISHER_REPORT_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_TRACKIER_TOP_PUBLISHER_REPORT_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}