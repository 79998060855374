import { configureStore } from '@reduxjs/toolkit';

import authReducer from './stores/reducers/auth';
import messageReducer from './stores/reducers/message';
import reportReducer from './stores/reducers/report';

// VOLUUM
import voluumReportOfferReducer from './stores/reducers/voluum/offerCampaigns';
import voluumReportCampaignReducer from './stores/reducers/voluum/Campaign';
import voluumReportAdvertiserReducer from './stores/reducers/voluum/Advertiser';
import voluumReportCountryReducer from './stores/reducers/voluum/Country';
import voluumReportTrafficSourceReducer from './stores/reducers/voluum/TrafficSource';
import voluumReportDailyReducer from './stores/reducers/voluum/Daily';

// TRACKIER
import trackierReportCampaignReducer from './stores/reducers/trackier/Campaign';
import trackierReportPublisherReducer from './stores/reducers/trackier/Publisher';
import trackierReportComparisonPublisherReducer from './stores/reducers/trackier/ComparisonPublisher';
import trackierReportDailyReducer from './stores/reducers/trackier/Daily';
import trackierConversionReportReducer from './stores/reducers/trackier/ConversionReport';
import trackierTopPublisherReportReducer from './stores/reducers/trackier/TopPublisherReport';
import trackierDailyClickReportReducer from './stores/reducers/trackier/DailyClickReport';

// TRACKIER PUBLISHER MANAGEMENT
import trackierPublisherManagementReducer from './stores/reducers/trackier/PublisherManagement';
import trackierPublisherManagementReportReducer from './stores/reducers/trackier/PublisherManagementReport'

// DASHBOARD - VOLUUM
import voluumDashboardInsightReducer from './stores/reducers/dashboard/voluum';

// DASHBOARD - TRACKIER
import trackierDashboardInsightReducer from './stores/reducers/dashboard/trackier';

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: {
      auth: authReducer,
      message: messageReducer,
      report: reportReducer,

      // VOLUUM
      offerCampaigns: voluumReportOfferReducer,
      voluumReportCampaign: voluumReportCampaignReducer,
      voluumReportAdvertiser: voluumReportAdvertiserReducer,
      voluumReportCountry: voluumReportCountryReducer,
      voluumReportTrafficSource: voluumReportTrafficSourceReducer,
      voluumReportDaily: voluumReportDailyReducer,

      // TRACKIER
      trackierReportCampaign: trackierReportCampaignReducer,
      trackierReportPublisher: trackierReportPublisherReducer,
      trackierReportDaily: trackierReportDailyReducer,
      trackierReportComparisonPublisher: trackierReportComparisonPublisherReducer,
      trackierConversionReport: trackierConversionReportReducer,
      trackierTopPublisherReport: trackierTopPublisherReportReducer,
      trackierDailyClickReport: trackierDailyClickReportReducer,

      // TRACKIER PUBLISHER MANAGEMENT
      trackierPublisherManagement: trackierPublisherManagementReducer,

      // TRACKIER PUBLISHER MANAGEMENT REPORT
      trackierPublisherManagementReport: trackierPublisherManagementReportReducer,

      // DASHBOARD
      voluumDashboardInsight: voluumDashboardInsightReducer,
      trackierDashboardInsight: trackierDashboardInsightReducer,
    },
    preloadedState,
  });

  return store;
}