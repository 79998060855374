import {
    SET_TRAFFIC_SOURCE,
    CLEAR_TRAFFIC_SOURCE,
    GET_TRAFFIC_SOURCE_SUCCESS,
    GET_TRAFFIC_SOURCE_FAILED,
} from '../../actions/type';

const initialState = { data: null, status: 'idle' };

export default function voluumTrafficSourceReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_TRAFFIC_SOURCE:
            return {
                ...state,
                data,
            }
        case CLEAR_TRAFFIC_SOURCE:
            return {
                ...state,
                data: null,
            }
        case GET_TRAFFIC_SOURCE_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_TRAFFIC_SOURCE_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}