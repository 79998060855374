import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    ME_SUCCESS,
    ME_FAIL,
} from './type';

import AuthService from '../../services/auth.service';

export const register = (email, phone, first_name, last_name, password) => (dispatch) => {
    return AuthService.register(email, phone, first_name, last_name, password).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const login = (username, password, from) => (dispatch) => {
    return AuthService.login(username, password, from).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(message);
        }
    );
};

export const updatePassword = (password, repassword) => (dispatch) => {
    return AuthService.updatePassword(password, repassword).then(() => {
        dispatch({
            type: LOGOUT,
        });
    });
};

export const logout = () => (dispatch) => {
    return AuthService.logout().then(() => {
        dispatch({
            type: LOGOUT,
        });
    });
};

export const me = () => (dispatch) => {
    return AuthService.me().then(
        (res) => {
            dispatch({
                type: ME_SUCCESS,
                payload: res.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                error.message ||
                error.toString();

            dispatch({
                type: ME_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            localStorage.removeItem('user');
            return Promise.reject(message);
        }
    );
};