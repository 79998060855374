export default function authHeader(path) {
    const user = JSON.parse(localStorage.getItem('user'));
    const header = {};

    if (['torazzo-questionnaire'].includes(path)) {
        Object.assign(header, { 'torazzo-questionnaire': true });
        return header;
    }

    if (['landing-page-generator', 'loyalty-rewards', 'scheduler'].includes(path) && user['dashboard-token']) {
        Object.assign(header, { 'dashboard-token': user['dashboard-token'] });
        return header;
    }

    if (!user) {
        return header;
    }

    if (user && user['dashboard-token']) {
        if (user.profile.is_admin) {
            Object.assign(header, {
                'dashboard-token': user['dashboard-token'],
                'cwauth-token': user['cwauth-token'],
            });
        } else {
            Object.assign(header, {
                'dashboard-token': user['dashboard-token'],
                'personal-token': user['personal-token'],
                'torazzo-token': user['torazzo-token'],
            });
        }
    }

    return header;
}