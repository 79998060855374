import {
    SET_CAMPAIGNS,
    CLEAR_CAMPAIGNS,
    GET_CAMPAIGNS_SUCCESS,
    GET_CAMPAIGNS_FAILED,
} from '../../actions/type';

const initialState = { data: null, status: 'idle' };

export default function voluumCampaignReducer(state = initialState, action) {
    const { type, data } = action;

    switch (type) {
        case SET_CAMPAIGNS:
            return {
                ...state,
                data,
            }
        case CLEAR_CAMPAIGNS:
            return {
                ...state,
                data: null,
            }
        case GET_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                status: 'success',
            }
        case GET_CAMPAIGNS_FAILED:
            return {
                ...state,
                error: data,
                status: 'failed',
            }

        default:
            return state;
    }
}