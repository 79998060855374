import axios from 'axios';
import authHeader from './auth-header';
import { DEV_AUTH_API, PROD_AUTH_API, IS_DEVELOPMENT } from '../config/url';

let API_URL = '';

if (IS_DEVELOPMENT) {
    API_URL = DEV_AUTH_API;
} else {
    API_URL = PROD_AUTH_API;
}

const register = (email, phone, first_name, last_name, password) => {
    return axios.post(API_URL + '/signup', {
        email,
        phone,
        password,
        first_name,
        last_name,
    });
};

const login = (email, password, from) => {
    axios.defaults.withCredentials = true;

    return axios
        .post(API_URL + '/login', {
            email,
            password,
        })
        .then((response) => {
            const is_admin = response?.data?.profile?.is_admin;

            const is_publisher = response?.data?.profile?.is_publisher;
            const is_active = response?.data?.profile?.is_active;

            if (!is_admin && is_publisher && !is_active) {
                return null
            }

            if (!is_admin && is_publisher && is_active) {
                localStorage.setItem('user', JSON.stringify({
                    'dashboard-token': response.data['dashboard-token'],
                    'profile': {
                        ...response.data.profile,
                        from,
                    },
                }));
                return response.data;
            }

            if (!is_admin) {
                if (is_publisher && is_active && response.data['dashboard-token']) {
                    localStorage.setItem('user', JSON.stringify({
                        'dashboard-token': response.data['dashboard-token'],
                        'profile': response.data.profile,
                    }));

                    return response.data;
                }
                if (response.data['dashboard-token'] && response.data['torazzo-token']) {
                    localStorage.setItem('user', JSON.stringify({
                        'personal-token': response.data['personal-token'],
                        'torazzo-token': response.data['torazzo-token'],
                        'dashboard-token': response.data['dashboard-token'],
                        'profile': response.data.profile,
                    }));
                }
            } else {
                if (response.data['cwauth-token']) {
                    localStorage.setItem('user', JSON.stringify({
                        'cwauth-token': response.data['cwauth-token'],
                        'dashboard-token': response.data['dashboard-token'],
                        'profile': response.data.profile,
                    }));
                }
            }

            return response.data;
        }).catch((err) => {
            throw err;
        });
};

const loginAs = (user_id, admin_email) => {
    return axios.get(API_URL + '/login-as',
        {
            headers: authHeader(),
            params: {
                user_id,
                admin_email,
            },
        }
    );
}

const forgotPassword = (email) => {
    return axios.get(API_URL + '/forgot-password',
        {
            headers: authHeader(),
            params: {
                email,
            },
        }
    );
}

const verifyResetPassword = (payload) => {
    return axios.post(API_URL + '/verify-reset-password', payload);
}

const logout = () => {
    const dashboardToken = authHeader()['dashboard-token'];

    if (!dashboardToken) {
        return true;
    }

    axios.defaults.withCredentials = true;
    return axios
        .delete(API_URL + '/logout', { headers: { 'dashboard-token': dashboardToken } })
        .then((response) => {
            localStorage.removeItem('user');
            return response.data;
        }).catch((err) => {
            if (err.response?.data?.error && (err.response?.data?.error).indexOf('token is expired by') >= 0) {
                localStorage.removeItem('user');
            }
            return err;
        }).finally(() => {
            localStorage.removeItem('user');
        });
};

const updatePassword = (password, repassword) => {
    const user = JSON.parse(localStorage.getItem('user'));

    return axios.put(API_URL + '/update-password', {
        email: user.profile.email,
        password: password,
        repassword: repassword
    });
};

const me = () => {
    return axios.get(API_URL + '/me', {
        headers: authHeader(),
    });
}

const services = {
    register,
    login,
    logout,
    me,
    updatePassword,
    loginAs,
    forgotPassword,
    verifyResetPassword,
};

export default services;