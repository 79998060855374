// ENVIRONMENT
export const IS_DEVELOPMENT = false;

// DEV
export const DEV_API_V1 = 'http://localhost:3001/api/v1';
export const DEV_AUTH_API = 'http://localhost:3001/api';
export const DEV_EXTERNAL_API = 'http://localhost:3001/api/external';

// PRODUCTION
export const PROD_API_V1 = 'https://torazzo.net/api/v1';
export const PROD_AUTH_API = 'https://torazzo.net/api';
export const PROD_EXTERNAL_API = 'https://torazzo.net/api/external';


// PRODUCTION - ARS
// export const PROD_API_V1 = 'http://167.71.29.166/api/v1';
// export const PROD_AUTH_API = 'http://167.71.29.166/api';

// PRODUCTION - Riverwidetech
// export const PROD_API_V1 = 'http://142.93.120.156/api/v1';
// export const PROD_AUTH_API = 'http://142.93.120.156/api';