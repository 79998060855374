import React, { Suspense, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '../stores/actions/auth';

import { DesktopOutlined, ShopOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Button, Row, Col, message, Space, Spin } from 'antd';
import { useSelector } from 'react-redux';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const Login = React.lazy(() => import('../views/Login'));
const ChangePassword = React.lazy(() => import('../views/UpdatePassword'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const Register = React.lazy(() => import('../views/Register'));

const Questionnaire = React.lazy(() => import('../views/Questionnaire'));

const LoyaltyHome = React.lazy(() => import('../views/Loyalty/Loyalty'));

// Routes
const AdminRoutes = React.lazy(() => import('../routes/admin'));
const PublisherRoutes = React.lazy(() => import('../routes/publisher'));

const Container = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(null);
    
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPublisherActive, setIsPublisherActive] = useState(false);
    const [isNeedChangePassword, setIsNeedChangePassword] = useState(false);

    const [loadingLogout, setLoadingLogout] = useState(false);

    const [menuDefault, setMenuDefault] = useState('');

    const [breadCrumbParent, setBreadCrumbParent] = useState('');
    const [breadCrumbChild, setBreadCrumbChild] = useState('');

    const [items, setItems] = useState([]);

    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (user?.['dashboard-token']) {
            setLoggedIn(true);
            setIsPublisherActive((user.profile?.is_publisher && user.profile?.is_active) || false);
            setIsAdmin(user.profile?.is_admin || false);

            // for publisher only
            const is_password_default = user.profile?.is_password_default || false;
            const is_from_admin = (user.profile?.from && user.profile?.from === 'admin') || false;

            setIsNeedChangePassword((is_password_default && !is_from_admin )|| false);
        } else {
            setLoggedIn(false);
        }
    }, [user]);

    useEffect(() => {
        if (isPublisherActive) {
            setItems([
                getItem('Dashboard', 'dashboard-report', <DesktopOutlined />),
                // getItem('Loyalty', 'loyalty-program/home', <ShopOutlined />),
                getItem('Report', 'publisher-report', <DesktopOutlined />, [
                    // { key: 'publisher-daily-report', label: 'Daily Report' },
                    { key: 'sub-publisher-report', label: 'Sub Pusblisher Report' },
                    { key: 'geo-publisher-report', label: 'Geo Publisher Report' },
                ]),
            ]);
        } else if (isAdmin) {
            setItems([
                getItem('Dashboard', 'dashboard-report', <DesktopOutlined />),
                getItem('Voluum', 'voluum-report', <DesktopOutlined />, [
                    { key: 'voluum-report-campaigns', label: 'Campaign Report' },
                    { key: 'voluum-report-offers', label: 'Offers report' },
                    { key: 'voluum-report-advertiser', label: 'Advertiser Report' },
                    { key: 'voluum-report-country', label: 'Country Report' },
                    { key: 'voluum-report-traffic-source', label: 'Traffic Source Report' },
                    { key: 'voluum-report-daily', label: 'Daily Report' },
                ]),
                getItem('Trackier', 'trackier-report', <DesktopOutlined />, [
                    { key: 'trackier-report-campaigns', label: 'Campaign Report' },
                    { key: 'trackier-report-publisher', label: 'Publisher Report' },
                    { key: 'trackier-report-daily', label: 'Daily Report' },
                    { key: 'trackier-report-comparison', label: 'Comparison Report' },
                    { key: 'trackier-conversion-report', label: 'Conversion Report' },
                    { key: 'trackier-top-publisher-report', label: 'Top Publisher Report' },
                    { key: 'trackier-daily-click-report', label: 'Daily Click Report' },
                ]),
                getItem('Management', 'trackier-management', < DesktopOutlined/>, [
                    { key: 'trackier-publisher-management', label: 'Trackier - Publisher' },
                    { key: 'trackier-publisher-management-report', label: 'Trackier - Publisher Report' },
                    { key: 'answers-publisher', label: 'Publisher Survey' },
                    { key: 'answers-advertiser', label: 'Advertiser Survey' },
                    { key: 'alert-report', label: 'Alert Report' },

                    // loyalty rewards - publisher
                    getItem('Loyalty', 'sub3', null, [
                        { key: '/loyalty-management/products', label: 'Products' },
                        { key: '/loyalty-management/orders', label: 'Orders' },
                    ]),
                    // { key: '/loyalty-management/products', label: 'Loyalty - products' },
                    // { key: '/loyalty-management/orders', label: 'Loyalty - orders' },
                ]),
                getItem('Landing Page', 'landing-page', < DesktopOutlined/>, [
                    { key: 'landing-page-generator-list', label: 'List' },
                    { key: 'landing-page-generator', label: 'Generator' },
                    { key: 'landing-page-generator-domain-list', label: 'Domain list' },
                    { key: 'landing-page-generator-report', label: 'Report' },
                    // { key: 'landing-page-web-generator', label: 'Web Generator' },
                ]),
                getItem('Scheduler', 'scheduler', < DesktopOutlined/>, [
                    { key: 'sub-publisher-acquition', label: 'Sub Publisher Acquition' },
                ]),
            ]);
        } else {
            setItems([]);
        }

    }, [isPublisherActive, isAdmin]);

    useEffect(() => {
        if ([
                '/survey/2023/publisher',
                '/survey/2023/advertiser',
                'loyalty-program/home',
                'loyalty-program/history',
                'loyalty-program/cart',
            ].includes(location.pathname)) {
            navigate(location.pathname);
            return;
        }
        if (location.pathname === '/register' && !isLoggedIn) {
            navigate('/register');
        } else if (location.pathname === '/reset-password' && !isLoggedIn) {
            navigate(`${location.pathname}${location.search}`);
        } else if (location.pathname === '/login' && isLoggedIn) {
            navigate('/');
        } else {
            if (isLoggedIn === false) {
                navigate('/login');
            }
            if (user?.profile?.is_password_default && isNeedChangePassword) {
                navigate('/update-password');
            } else if (isLoggedIn === true) {
                navigate(location.pathname || '/');

                if (location.pathname === '/') {
                    setMenuDefault('dashboard-report');
                } else if (location.pathname === '/update-password' && !isNeedChangePassword) {
                    navigate('/');
                } else {
                    setMenuDefault(location.pathname.replace('/', ''));
                }
            }
        }
    }, [isLoggedIn, navigate, location.pathname]);

    useEffect(() => {
        if (menuDefault) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item && item.children) {
                    const selectedMenu = item.children.find((child) => child.key === menuDefault);
                    if (selectedMenu) {
                        setBreadCrumbParent(item.label);
                        setBreadCrumbChild(selectedMenu.label);
                    }
                } else if (item && !item.children) {
                    setBreadCrumbParent(item.label);
                    setBreadCrumbChild('');
                }
            }
        }
    }, [menuDefault, items]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleLogout = () => {
        setLoadingLogout(true);
        dispatch(logout())
            .then(() => {
                navigate('/login');
            }).finally(() => {
                message.success('Logout successful');
                setLoadingLogout(false);
            });
    }

    const clickMenu = ({ key }) => {
        if (key === 'loyalty-program/home') {
            window.open('/loyalty-program/home');
            return;
        }

        if (key === 'dashboard-report') {
            navigate('/');
        } else {
            navigate(key);
        }
    };

    const handleOnCollapse = (value) => {
        setCollapsed(value);
    }

    if (isLoggedIn == null) return (
        <Layout
            style={{
                minHeight: '100vh',
                backgroundColor: '#233142'
            }}
        >
            <Space
                size="large"
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Spin size="large" />
            </Space>   
        </Layout>
    )

    return (
        <Layout
            style={{
                minHeight: '100vh',
                backgroundColor: ['/survey/2023/publisher', '/survey/2023/advertiser'].includes(location.pathname) ? '#FEFEFE' : '#233142'
            }}
        >
            {['/survey/2023/publisher', '/survey/2023/advertiser'].includes(location.pathname) && (
                <Suspense fallback={<></>}>
                    <Questionnaire />
                </Suspense>
            )}

            {['/loyalty-program/home', '/loyalty-program/cart', ,'/loyalty-program/history', '/loyalty-program/checkout'].includes(location.pathname) && (
                <Suspense fallback={<></>}>
                    <LoyaltyHome />
                </Suspense>
            )}

            {
                !isLoggedIn &&
                location.pathname === '/register' &&
                !([
                    '/survey/2023/publisher',
                    '/survey/2023/advertiser',
                    '/loyalty-program/home',
                    '/loyalty-program/cart',
                    '/loyalty-program/history',
                    '/loyalty-program/checkout',
                ].includes(location.pathname)) &&
                (
                    <Suspense fallback={<></>}>
                        <Register />
                    </Suspense>
                )
            }

            {
                isLoggedIn === false &&
                location.pathname !== '/register' &&
                location.pathname !== '/reset-password' &&
                !([
                    '/survey/2023/publisher',
                    '/survey/2023/advertiser',
                    '/loyalty-program/home',
                ].includes(location.pathname)) &&
                (
                    <Suspense fallback={<></>}>
                        <Login />
                    </Suspense>
                )
            }

            {
                location.pathname !== '/register' &&
                location.pathname === '/reset-password' &&
                !([
                    '/survey/2023/publisher',
                    '/survey/2023/advertiser',
                    '/loyalty-program/home',
                    '/loyalty-program/cart',
                    '/loyalty-program/history',
                    '/loyalty-program/checkout',
                ].includes(location.pathname)) &&
                (
                    <Suspense fallback={<></>}>
                        <ResetPassword />
                    </Suspense>
                )
            }

            {
                isNeedChangePassword &&
                location.pathname === '/update-password' &&
                !([
                    '/survey/2023/publisher',
                    '/survey/2023/advertiser',
                    '/loyalty-program/home',
                    '/loyalty-program/cart',
                    '/loyalty-program/history',
                    '/loyalty-program/checkout',
                ].includes(location.pathname)) &&
                (
                    <Suspense fallback={<></>}>
                        <ChangePassword />
                    </Suspense>
                )
            }

            {
                isLoggedIn === true &&
                (
                    isAdmin || isPublisherActive
                ) &&
                !isNeedChangePassword &&
                (
                    location.pathname !== '/register' || location.pathname !== '/reset-password'
                ) &&
                !([
                    '/survey/2023/publisher',
                    '/survey/2023/advertiser',
                    '/loyalty-program/home',
                    '/loyalty-program/cart',
                    '/loyalty-program/history',
                    '/loyalty-program/checkout',
                ].includes(location.pathname)) && (
                <>
                    <Sider
                        collapsible
                        width={230}
                        collapsed={collapsed}
                        onCollapse={handleOnCollapse}
                    >
                        <img
                            src='/torazzo-header-logo.png'
                            style={{
                                height: 32,
                                margin: 16,
                                maxWidth: collapsed ? 32 : 'none',
                                objectFit: collapsed ? 'cover' : 'contain',
                                objectPosition: collapsed ? 'left' : 'center',

                            }}
                            alt="torazzo-logo"
                        />
                        <Menu
                            theme="dark"
                            defaultSelectedKeys={[menuDefault]}
                            defaultOpenKeys={['report']}
                            selectedKeys={[menuDefault]}
                            mode="inline"
                            items={items}
                            onClick={clickMenu}
                        />
                    </Sider>
                    <Layout className="site-layout">
                        <Header
                            style={{
                                padding: 0,
                                background: colorBgContainer,
                            }}
                            className="site-header-layout"
                        >
                            <Row justify="end" style={{ height: '100%' }}>
                                <Col span={6} className="header-profile">
                                    <Button loading={loadingLogout} onClick={handleLogout}>
                                        logout
                                    </Button>
                                </Col>
                            </Row>
                        </Header>
                        <Content
                            className="site-content-layout"
                        >
                            <Breadcrumb
                                style={{
                                    margin: '16px 0',
                                }}
                                items={
                                    breadCrumbChild ? ([
                                        { title: breadCrumbParent },
                                        { title: breadCrumbChild },
                                    ]) : ([
                                        { title: breadCrumbParent },
                                    ])
                                }
                            />
                            <div
                                style={{
                                    padding: 24,
                                    minHeight: 360,
                                    background: colorBgContainer,
                                }}
                            >
                                {/* <Routes> */}
                                    {isAdmin && (
                                        <AdminRoutes />
                                    )}

                                    {isPublisherActive && (
                                        <PublisherRoutes />
                                    )}
                                {/* </Routes> */}
                            </div>
                        </Content>
                        <Footer
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            Torazzo Dashboard ©2023
                        </Footer>
                    </Layout>
                </>
            )}
        </Layout>
    );
};

export default Container;